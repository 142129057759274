import React from "react";
import "./workdetail.css";
import img_icon from "../../assets/perport/perport_icon.jpg"
import img_play from "../../assets/perport/play_app.jpg"
import img_method from "../../assets/perport/proposed_method.png"
import img_result from "../../assets/perport/result_en.png"


const Onawa = () => {
    return (   
        <section class="section">
            <div className="workdetail__container container">
                <img src={img_play} alt="play img" className="workdetail__img"></img>
                <div className="workdetail__title">Perport</div>
                <div className="workdetail__credit">
                    Akinori Maeda
                </div>
                <div className="workdetail__caption">
                    <div className="eng">
                        This Research attempts to support practice for playing a uniform timbre in basic percussion exercises. I proposed A deep learning model to learn how advanced players perceive sounds. The timbre is visualized by plotting each sound on a two-dimensional space, and the concentration of points expresses uniformity. The evaluation experiment confirmed that the point clusters visualizing the sounds of music college students were concentrated, whereas those of beginners tended to be scattered.                
                    </div>
                    打楽器の基礎練習における均一な音色を奏でる練習を支援する試み。上級者の音の聴こえ方を学習した深層学習モデルを考案した。1音ずつ2次元空間上にプロットすることで音色が可視化され、点の集中によって均一性が表現される。評価実験では、音楽大学生の音を可視化した点群は集中し、初級者の点群は散らばる傾向にあることが確認された。
                </div>
                <div className="perport__title">
                    Proposed Method
                </div>
                <img src={img_method} alt="method img" className="workdetail__img"></img>
                <div className="perport__title">
                    Visualization
                </div>
                <img src={img_result} alt="result img" className="workdetail__img"></img>
                <div className="perport__title">
                    Demo App
                </div>
                <div className="youtube_wrap">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Hqtqnc56tWU?si=RD3kQGcy7zeADuD2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="perport__title">
                    Publications
                </div>
                <div className="workdetail__credit">
                    <ul className="ul-with-point">
                        <li>
                            前田哲徳, 西村雅史, "モバイルデバイス上での打楽器左右打音の音色と強度のリアルタイム可視化", 情報処理学会研究報告, Vol.2024-MUS-139, No.30, pp.1-7, 2024. <a href="https://ipsj.ixsq.nii.ac.jp/ej/?action=pages_view_main&active_action=repository_view_main_item_detail&item_id=232847&item_no=1&page_id=13&block_id=8" className="workdetail__weight" target="_blank" rel="noopener noreferrer">[Link]</a> <br></br>
                        </li>
                        <li>
                            Akinori Maeda, Masafumi Nishimura, “Timbre Uniformity Visualization for Percussion Training Using Contrastive Loss”, 20th RISP International Workshop on Nonlinear Circuits, Communications and Signal Processing (NCSP2023), pp.206-209, 2023.<br></br>
                        </li>
                        <li>
                            前田哲徳, 西村雅史, "コンタクトマイクで収録した打楽器練習用ゴムパッド音の対照損失に基づく音色可視化", 日本音響学会第148回(2022年秋季)研究発表会, 2022.<br></br>
                        </li>
                        <li>
                            前田哲徳, 西村雅史, "Contrastive Lossを用いた打楽器練習用ゴムパッドの音色可視化", 情報処理学会研究報告, Vol.2022-MUS-134, No.8, pp.1-6, 2022. <a href="https://ipsj.ixsq.nii.ac.jp/ej/index.php?active_action=repository_view_main_item_detail&page_id=13&block_id=8&item_id=218400&item_no=1" className="workdetail__weight" target="_blank" rel="noopener noreferrer">[Link]</a> <br></br>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
    }
    
export default Onawa