import React from 'react'
import "./career.css";

const Experience = () => {
    return (
        <div>
            <h2 className="career__title">Experience</h2>
            <div>
                <h3 className="organization__title">SoftBank Corp.</h3>
                <div className="organization__subtitle">R&D Engineer at SoftBank Research Institute of Advanced Technology</div>
                <div className="organization__calendar">
                    2024 -
                </div>
            </div>

            <div>
                <h3 className="organization__title">Sony CSL - Kyoto</h3>
                <div className="organization__subtitle">Research Assistant (Part-time)・Human Augmention & AI</div>
                <div className="organization__calendar">
                    2023 - 2024
                </div>
            </div>

            <div>
                <h3 className="organization__title">Exawizards Inc.</h3>
                <div className="organization__subtitle">Software Engineer (Summer Internship)・iOS App Development</div>
                <div className="organization__calendar">
                    2022 (5weeks)
                </div>
            </div>
        </div>
    )
}

export default Experience