import React from "react";
import "./workdetail.css";
import OnawaImg2 from "../../assets/onawa/TDC2023_C_00028.png"
import OnawaImg3 from "../../assets/onawa/TDC2023_C_00075.JPG"

const Onawa = () => {
    return (   
        <section class="section">
            <div className="workdetail__container container">
                <img src={OnawaImg2} alt="onawa_img_2" className="workdetail__img"></img>
                <img src={OnawaImg3} alt="onawa_img_3" className="workdetail__img"></img>
                <div className="youtube_wrap">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/bD3KzCbqQDI?si=hQvhe6QYOLBAOKSx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="workdetail__title">
                    <div className="eng">
                        long jump rope in the deep sea
                    </div>
                    深海大縄跳び
                </div>
                <div className="workdetail__caption">
                    <div className="eng">
                        In this work, a new long jump rope experience is provided using the facilities of TBS Tech Design X. When players jump, the number of jellyfish on the wall-mounted LED display increases, accompanied by lighting, sound, and video effects. It's a game where players continue jumping for one minute and compete to increase the number of jellyfish.
                    </div>
                    この作品では、TBS Tech Design Xの設備を用いた新たな大縄跳びの体験を提供する。プレイヤーがジャンプすると、映像、音、照明の演出と共に壁面のLEDディスプレイにクラゲが増えていく。1分間ジャンプを続け、増え続けるクラゲの数を競うゲームとなっている。
                </div>
                <div className="workdetailitem__title">
                    Credit
                </div>
                <div className="workdetail__credit">
                    Project Planning: <span className="workdetail__weight">Akinori Maeda</span><br></br>
                    Video: Momona Otsu, Yuki Kamite<br></br>
                    3DCG Modeling: Momona Otsu<br></br>
                    UI Design: Momona Otsu, Yuki Kamite<br></br>
                    Sound Programming: <span className="workdetail__weight">Akinori Maeda</span><br></br>
                    Lighting: Yuki Kamite<br></br>
                    Game System Development: Yuma Matsuoka<br></br>
                    Interactive System Development: Hikaru Hamada, Yuma Matsuoka, Yuki Kamite<br></br>
                    Visual Programming: Yuma Matsuoka, Yuki Kamite<br></br>
                    Support: TBS Tech Design Lab
                </div>
                <div className="workdetailitem__title">
                    Report
                </div>
                <div className="workdetail__credit">
                    TBS INNOVATION LAND | 若き才能が躍動！TBS式学生向け空間演出ハッカソン「Tech Design Camp 2023」開催レポート <a href="https://innovation.tbs.co.jp/neo_report/578/" className="workdetail__weight" target="_blank" rel="noopener noreferrer">[Link]</a>
                </div>
            </div>
        </section>
    )
    }
    
export default Onawa