import React from "react";
import "./workdetail.css";
import img_line from "../../assets/tomato/line.jpg"
import img_marble from "../../assets/tomato/marble.jpg"
import img_original from "../../assets/tomato/original.jpg"
import img_hand from "../../assets/tomato/tomato_hand_icon.jpg"

const Tomato = () => {
    return (   
        <section className="section">
            <div className="workdetail__container container">
                <div className="workdetail__2grid grid">
                    <img src={img_marble} alt="marble img" ></img>
                    <img src={img_line} alt="line img" ></img>
                    <img src={img_hand} alt="hand img" ></img>
                    <img src={img_original} alt="original tomato img" ></img>
                </div>
                <div className="workdetail__title">
                    mutable(tomato | you)
                </div>
                <div className="workdetail__credit">
                    Akinori Maeda
                </div>
                <div className="workdetail__credit">
                    <div className="eng">
                        This work was presented at an exhibition in 2023 at Shizuoka University of Art and Culture, showcasing various ways of displaying tomatoes. 
                    </div>
                    本作品は、2023年に静岡文化芸術大学で開催された、トマトの様々な見せ方をテーマとした展示「派生する展示」にて発表された。
                </div>
                <div className="workdetail__caption">
                    <div className="eng">
                        It features a real-time video projection of tomatoes, with changes in curvature and persistence, accompanied by generated sounds. The work demonstrates how human sensory organs might perceive things differently when altered. It explores the moments when things are perceived differently, questioning whether this change originates from the object itself, the observer, or both. It highlights the difficulty of making definitive observations in our ever-changing perspectives.
                    </div>
                    トマトを映し出すリアルタイムの映像の湾曲や持続性変化、そしてそれに伴って生成された音により、人間の感覚器官が変化した際に物事をどのように認識するかを示す作品である。<br/>
                    物事が異なる形で認識される瞬間、その変化が物そのものに由来するのか、あるいは観察者自身に由来するのか、それとも双方に由来するのか。相対的に変化し続ける我々に一義的な観察は容易ではない。
                </div>
            </div>
        </section>
    )
    }
    
export default Tomato
