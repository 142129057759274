import React from 'react'
import "./career.css";

const Education = () => {
    return (
        <div>
            <h2 className="career__title">Education</h2>
            <div>
                <h3 className="organization__title">Shizuoka University</h3>
                <div className="organization__subtitle">Master ・ Informatics</div>
                <div className="organization__calendar">
                    2022 - 2024
                </div>
            </div>

            <div>
                <h3 className="organization__title">Shizuoka University</h3>
                <div className="organization__subtitle">Bachelor ・ Informatics</div>
                <div className="organization__calendar">
                    2018 - 2022
                </div>
            </div>
        </div>
    )
}

export default Education