import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import "./header.css"

const Header = () => {
    /*=================== Change background header =================*/
    window.addEventListener('scroll', function () {
        const header = document.querySelector('.header');
        if (this.scrollY >= 80) header.classList.add("scroll-header");
        else header.classList.remove("scroll-header");
    });
    /*=================== Toggle Menu =================*/
    const[Toggle, showMenu] = useState(false);

    return (
        <header className="header">
            <nav className="nav container">
                <Link to = "/#works" className="nav__logo">
                    Akinori Maeda
                </Link>
                <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                    <ul className="nav__list grid">
                        <li className="nav__item">
                            <Link to = "/#works" className="nav__link">
                                <i className="uil uil-box nav__icon"></i> Works
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link to = "/#home" className="nav__link">
                                    <i className="uil uil-annoyed-alt nav__icon"></i> About
                            </Link>
                        </li>
                    </ul>
                    <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>
                </div>
                <div className="uil uil-angle-up nav__toggle" onClick={() => showMenu(!Toggle)}></div>
            </nav>
        </header>
    )
}

export default Header