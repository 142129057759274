import React from "react";
import "./workdetail.css";
import img_fire from "../../assets/mistyfire/fire.jpg"
import img_fire_2 from "../../assets/mistyfire/fire_2.jpg"
import img_system from "../../assets/mistyfire/system.jpg"

const Mistyfire = () => {
    return (   
        <section class="section">
            <div className="workdetail__container container">
                <img src={img_fire} alt="fire img" className="workdetail__img"></img>
                <img src={img_fire_2} alt="fire img" className="workdetail__img"></img>
                <img src={img_system} alt="system img" className="workdetail__img"></img>
                <div className="youtube_wrap">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/vypgsh_1KB4?si=Eb5bqtCE1spzxK5F" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                
                </div>
                <div className="workdetail__title">
                    <div className="eng">
                        Misty Fire as Life
                    </div>
                    命の火朧
                </div>
                <div className="workdetail__caption">
                    Akinori Maeda, Kyosuke Teramoto, Yuya Tsuda
                </div>
                <div className="workdetail__caption">
                    <div className="eng">
                        In this work, we propose a visual-haptic display that represents the participants' life as fire. The visual-haptic display is composed of visual presentation using a fog display and haptic feedback through mid-air ultrasound. The user's heartbeat is measured, and in accordance with it, both the imagery and the acoustic radiation pressure from the mid-air ultrasound are altered to represent the user's life. 
                    </div>
                    この作品では、体験者の命を火として表現する視触覚ディスプレイを提案する。視触覚ディスプレイは、フォグディスプレイを使用した視覚提示と、空中超音波による触覚提示で構成される。測定された体験者の心拍数によって、映像表現と空中超音波からの音響放射圧が変化し、体験者の命を表現する。
                </div>
                <div className="workdetailitem__title">
                    Related Links
                </div>
                <div className="workdetail__credit">
                    命の火朧(2020) | IVRC HISTORY ARCHIVE <a href="https://ivrc.net/archive/%E5%91%BD%E3%81%AE%E7%81%AB%E6%9C%A7-2020/" className="workdetail__weight" target="_blank" rel="noopener noreferrer">[Link]</a>
                </div>
            </div>
        </section>
    )
    }
    
export default Mistyfire
