import React from 'react'
import Social from './Social';

const Data = () => {
    return (
        <div className='home__data'>
            <h1 className="home__title">
                <div className="eng">
                    Akinori Maeda
                </div>
                <div className='name__ja'>
                    前田 哲徳
                </div>
            </h1>
            <p className="home__description">
            I am an R&D Engineer who loves the new culture crafted by computers.
            </p>
            <Social />
        </div>
    )
}

export default Data