import React from 'react';
import "./home.css";
import Data from './Data';
import Education from './Education';
import Experience from './Experience';

const Home = () => {
    return (
        <section className="home section" id="home">
            <div className="home__container container grid">
                <div className="home__content grid">
                    <div className="home__img"></div>
                    <Data />
                </div>
                <div className="career__content grid">
                    <Experience />
                    <Education />
                </div>
            </div>
        </section>
    )
}

export default Home