import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollTop from './components/Util/ScrollTop';
import ScrollToAnchor from './components/Util/ScrollToAnchor';

import Header from './components/header/Header';
import Top from './components/top/Top';
import Footer from './components/footer/Footer';
import Scrollup from './components/scrollup/Scrollup';

import Onawa from './components/works/Onawa';
import Mistyfire from './components/works/Mistyfire';
import Papersing from './components/works/Papersing';
import Tomato from './components/works/Tomato';
import Perport from './components/works/Perport';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <main className="main">
        <ScrollTop />
        <ScrollToAnchor />
        <Routes>
          <Route path="/" element={<Top />} />
          <Route path="/onawa" element={<Onawa />} />
          <Route path="/mistyfire" element={<Mistyfire />} />
          <Route path="/papersing" element={<Papersing />} />
          <Route path="/tomato" element={<Tomato />} />
          <Route path="/perport" element={<Perport />} />
        </Routes>
      </main>
      <Footer />
      <Scrollup />
    </BrowserRouter>
  );
}

export default App;
