import React from 'react';
import Home from '..//home/Home';
import Works from '../works/Works';

const Top = () => {
    return (
        <div>
            <Works />
            <Home />
        </div>
    )
}

export default Top