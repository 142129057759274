import React from "react";
import "./workdetail.css";
import img_front from "../../assets/papersing/front_face.jpg"
import img_side from "../../assets/papersing/side_face.jpg"

const Papersing = () => {
    return (   
        <section className="section">
            <div className="workdetail__container container">
                <img src={img_front} alt="front img" className="workdetail__img"></img>
                <img src={img_side} alt="side img" className="workdetail__img"></img>
                <div className="youtube_wrap">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/qGM1h-DuFDo?si=kuVMIKAjO-OvHYbb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="workdetail__title">
                    <div className="eng">
                        Paper Sing
                    </div>
                    紙は鳴く
                </div>
                <div className="workdetail__caption">
                    Akinori Maeda
                </div>
                <div className="workdetail__caption">
                    <div className="eng">
                        In this work, participants read the novel 'Neboke - Yumeno Kyusaku (1922)' by tearing paper. In today's CtoC information market, tweets trimmed from stories are separated from their context and weave new narratives. By consuming a work created in traditional media 100 years ago in the format of new media, this project presents the transformation of information consumption over the past century.
                    </div>
                    本作品では、体験者が紙を破ることで小説「寝ぼけ - 夢野久作 (1922)」を読む。現代のCtoC情報マーケットでは、物語からトリミングされたつぶやきは文脈から切り分けられ、新たな物語を紡ぐ。100年前に従来のメディアで作られた作品を、新たなメディアのフォーマットで摂取することで、過去1世紀における情報消費の変容を提示する。
                </div>
                <div className="workdetailitem__title">
                    Related Links
                </div>
                <div className="workdetail__credit">
                    Make: Japan | Hamamtsu Micro Maker Fair 2022 <a href="https://makezine.jp/event/makerfaire/hmmf2022/" className="workdetail__weight" target="_blank" rel="noopener noreferrer">[Link]</a> <br></br>
                    紙は鳴く(2022) | IVRC HISTORY ARCHIVE <a href="https://ivrc.net/archive/%E7%B4%99%E3%81%AF%E9%B3%B4%E3%81%8F2022/" className="workdetail__weight" target="_blank" rel="noopener noreferrer">[Link]</a> <br></br>
                </div>
            </div>
        </section>
    )
    }
    
export default Papersing
