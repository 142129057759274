import React from 'react'
import x_icon from "../../assets/logo-black.png"

const Social = () => {
    return (
        <div className='home__sns'>
            <a href="https://www.instagram.com/_06_ma5/" className="home__sns-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-instagram"></i>
            </a>
            <a href="https://twitter.com/NEW_SPEEDHOCKEY" className="home__sns-icon" target="_blank" rel="noreferrer" aria-label="X">
                <img src={x_icon} className="x__icon" alt="x icon"/>
            </a>
            <a href="https://github.com/maeda56" className="home__sns-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-github-alt"></i>
            </a>
        </div>
    )
}

export default Social