import React from 'react'
import './footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer__container container">
                <div className="footer__copy">&#169; Akinori Maeda 2023</div>
            </div>
        </footer>
    )
}

export default Footer